.video-container {
    position: relative;
    background: #3b3b3b;
    aspect-ratio: 16/9;
    border-radius: 6px;
    overflow: hidden;
}

.video-container video {
    width: 100%;
    height: 100%;
}

/* .video-container video {
    width: 100%;
    height: 100%;
}

.video-container .participant-data {
    position: absolute;
    top: 0;
    right: 0;
}

.participant-data p {
    background: #1C1C1E;
    margin: 0;
    padding: 0 5px;
    color: #fff;
    border-radius: 25px;
    font-size:14px;
  
} */

.video-container .participant-data {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.participant-data p {
    position: absolute;
    right: 0px;
    background: #1C1C1E;
    margin: 0;
    padding: 0 5px;
    color: #fff;
    border-radius: 25px;
    font-size:14px;
  
}

.participant-data-2 p {
   
    background: #1C1C1E;
    margin: 0;
    padding: 0 5px;
    color: #fff;
    border-radius: 25px;
    font-size:14px;
  
}

/* Media Queries */
@media screen and (max-width: 480px) {
    .video-container {
        aspect-ratio: 9/16;
    }
}


