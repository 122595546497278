body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.form-group {
  position: relative;
  margin-bottom: 30px;
}

.autocomplete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input-field {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 1px 0;
  width: 302px;
  height: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  /* position: absolute; */
  overflow-wrap: anywhere;
  color: #fff;
  z-index: 999;
  background: #1f2b37;
}


.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: hidden;
}


.suggestions-list li:hover {
  background-color: #0e1013;
}

.selected-value {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}


.text-container > input {
            margin-top: 4px;
            height: 50px;
            width: 100%;
            /* outline: none; */
            /* border: none; */
            padding: 0 20px 0 20px;
            font-size: 20px;
        }

.progress{
  --mdb-progress-height:15px !important;
}

.form-control:focus{
  box-shadow: none !important;
} 


.closeModalBtn{
  background: #dc3545;
    border-radius: 44px;
    width: 40px;
    height: 40px;
    border: 0px;
    color: #fff;
    margin-right: 20px;
    margin-top: 14px;
    float:right;

}

.closeModalBtn:hover{
  opacity: 0.9;
}

.btn.btn-primary{background-color: #1f2b37}
/* .btn.btn-primary:hover{background-color: #111418 !important} */

.modal-content{
  width: 700px !important;
}


.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}


.dropdown-toggle.btn.btn-primary{
  background: none !important;
    border: 0 !important;
 }

 .dropdown-toggle::after{
  display: none !important;
 }

 div.dropdown-menu.show{
  background: #f7f7f8 !important;
 }

 .dropdown-item:focus, .dropdown-item:hover{
  background-color:#011226 !important;
 }





/* Chat */


.str-chat__send-button{
  display: block !important;
}

.str-chat-channel{
  max-height: 70vh !important;
}
.str-chat__container{
  border: 1px solid;
}
.str-chat__message-attachment-dynamic-size.str-chat__message-attachment--image{
  width: 310px !important;
  padding: 10px !important;
}
.str-chat__message--me .str-chat__message-inner, .str-chat__message-simple--me .str-chat__message-inner{
  padding:1px !important;
}
.str-chat__avatar-fallback{
  z-index: 11;
}
@media screen and (max-width: 450px) {
  #webCanvas{
    width: 100% !important;
     bottom:-10px !important;
    margin: 0px !important;

  }
  #openchat{
    width: 40% !important;
  }
  
}

@media only screen and (min-device-width: 480px) 
             and (max-device-width: 640px) 
             and (orientation: landscape) {

              #webCanvas{
            /* width: 100% !important; */
            display: none !important;
            bottom:-10px !important;
            margin: 0px !important;

          }


}
.str-chat__channel-list{
 background-color: #01122699 !important;
  /* background-color: #10dec7 !important; */
  /* background: linear-gradient(90deg, rgba(13,202,240,1) 24%, rgba(16,222,199,1) 65%)!important; */
}

.str-chat.messaging, .str-chat.commerce{
  /* background-color: #01122663 !important; */
  /* background-color: #fcfcfc !important; */
}

.str-chat-channel.messaging .str-chat__main-panel{
  padding:0px !important;
}

.str-chat__avatar-fallback{
  background-color: #5a8dee !important;
  border-radius: 25px !important;
}
.str-chat__channel-list-messenger
{
  background-color: #0b1b2e00 !important;
  /* background-color: #10dec7 !important */
  /* background-color: #01122699 !important; */
  /* background-color: #2b3849 !important; */
  /* background: linear-gradient(90deg, rgba(13,202,240,1) 24%, rgba(16,222,199,1) 65%) !important; */
   /* 5f6a77 */
}
.str-chat__send-button > svg path{
  fill:#b10404 !important;
}
.str-chat__channel-preview-messenger--active{
  box-shadow: 4px 4px 9px 3px #011226 !important;
}

.str-chat__message-simple-name,.str-chat__message-simple-status>.str-chat__avatar, .str-chat__message-simple-status>.str-chat-angular__avatar-host .str-chat__avatar{
  display: none !important;
}

.str-chat__channel-list.str-chat__channel-list-react{
  height: 70vh;
}
.str-chat__channel-list.str-chat__channel-list-react,.str-chat__channel-preview-messenger--last-message{
     background-color: #0112261f !important; 
}

.str-chat__message--me .str-chat__message-text-inner, .str-chat__message-simple--me .str-chat__message-text-inner{
  background-color: #011226 !important; 
}
.str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar{
  display: none !important;
}

.str-chat__message-text-inner,.str-chat__message-simple-text-inner{
 /* color:#011226 !important; */
 color:#fff !important;
 background: #5a8dee !important;
}
.str-chat__message--me.str-chat__message-text-inner, .str-chat__message-simple--me.str-chat__message-text-inner{
 color:#fff !important;
 background: #011226;
}
.str-chat__textarea>textarea:focus,
.str-chat__input-flat.str-chat__textarea>textarea:focus{
  box-shadow: 0 0 0 1px  #fff !important;
}

.str-chat__textarea>textarea{
/* .str-chat__input-flat.str-chat__textarea>textarea{ */
  background-color: rgba(255, 255, 0, 0) !important;
  border: 0.5px solid #fff !important;
  padding: 20px 15px !important;

}

/* textarea{
  color: #fff !important;
  width: 80% !important;
margin-left: 80px !important;
} */

textarea::placeholder {
color: #fff !important; 

 }
 .rfu-file-upload-button,
 div.str-chat__input-flat.rfu-file-upload-button{
  left: 40px !important;
  right: unset !important;
  opacity: .7;
 }

 .rfu-file-upload-button:hover,
 div.str-chat__input-flat.rfu-file-upload-button:hover{
  opacity: 1;
 }

 button.str-chat__input-flat-emojiselect{
  left:0 !important;
 }

.messaging.str-chat ,.str-chat__list,
.messaging.str-chat.str-chat__list{
 background: #0b1b2e3b !important;
}

.str-chat__input-flat{
  background: #0b1b2e !important;
}

.str-chat__message-simple__actions__action svg{
  fill:#fff !important;
}
.str-chat__date-separator-date{
  color:#fff !important;
}

.str-chat__message-data, .str-chat__message-simple-data{
  color:#fff !important;
}

.str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar{
  display: none !important;
}
.str-chat__tooltip{
  display: none !important;
}

.str-chat__send-button{
  background-image: url('../../public/assets/send.svg') !important;
   background-size: contain !important;
    background-repeat: no-repeat !important;
    /* width: 6.5% !important; */
    opacity: .7;

  /* width: 35px !important;
height: 35px !important;
margin-top: 12px; */

}
.str-chat__send-button:hover{
  opacity: 1;
}

.str-chat__input-flat-emojiselect svg{
  fill:#fff !important;
  opacity: 0.7 !important;
}

.str-chat__input-flat-emojiselect svg:hover{
  opacity: 1 !important;
}

.rfu-file-upload-button{
  background: url('../../public/assets/upload.svg') !important;
  background-size: contain !important;
  width: 27px !important;
  height: 27px !important;
  top:18px !important;

}
span.str-chat__input-flat-fileupload > svg{
  visibility: hidden !important;
}
button.str-chat__send-button > svg{
  display: none !important;
}

.messaging.str-chat.str-chat__list{
   background-color: #0b1b2e87 !important;
}

div.rfu-file-upload-button label{
  width: 31px !important;
}


/* // icons */
.str-chat__message-simple__actions__action.str-chat__message-simple__actions__action--reactions.str-chat__message-reactions-button,
.str-chat__message-simple__actions__action.str-chat__message-simple__actions__action--thread.str-chat__message-reply-in-thread-button,
.str-chat__message-actions-box-button
{
  background: #0b1b2e !important;
  padding: 3px !important;
  border-radius: 50px !important;
  /* width: 18px !important;
  height: 18px !important; */
}

.unread-badge{
  color: white;
  width: 50px;
  background: #dc3545;
  border-radius: 50px;
  text-align: center;
}



::-webkit-scrollbar {
  width: 7px;
  
  }

  /* Track - vertical scrollbar */
  ::-webkit-scrollbar-track {
    background: #011226;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }


  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }



  /* @media screen and (min-width: 1370px) {
    
    #webCanvas{
      width: 45.5vw;
    }
  }

  
  @media screen and (max-width: 1370px) {
    
    #webCanvas{
      width: 70vw;
    }
  } */

  .str-chat__channel-preview-messenger--active{
   /* background-color: #0b1b2e !important; */
   background-color: #ffffff40 !important;

  }

  .str-chat__channel-preview-messenger--name,.str-chat__channel-preview-messenger--last-message{
    color:#fff;
  }

.messaging.str-chat, .str-chat__header-livestream{
/* background: #011226CC !important; */
background: #0b1b2ead !important;
color: #fff !important;
}


.dolbybtn{
  background: none !important;
  border:none !important;
  cursor: pointer !important;
}
.dolbybtn:hover{
  opacity: 0.8 !important;
}

.badge {
  position: absolute;
  top: 3px;
  left:121;
  background-color: #ea4335;
  color: #fff;
  border-radius: 50%; 
  padding: 5px 10px;
  font-size: 12px;
}


div.str-chat__channel-list-messenger__main.str-chat__channel-list-messenger-react__main{
 margin-top: 35px !important;
}


.str-chat__channel-preview-messenger .str-chat__channel-preview-messenger--right .str-chat__channel-preview-end-first-row .str-chat__channel-preview-unread-badge{
  color:#011226 !important;
  background: #fff !important;
}

.str-chat__list-notifications{
  background: none !important;
}

/* @media (min-width: 840px) and (max-width: 857px) {

  textarea{
    width: 85% !important;
    margin-left: 95px !important;
  }

  #webCanvas{
    width: 50vw;
  }
 
} */


.emotes_btn{
  width: 215px;
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom: 25px;
  background-color: #fff;
}

.closebtnmodal{
  float: right;
  background: 0px;
  border-radius: 9px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 11px;
}





/* html {
	position: relative;
	min-height: 100%;
} */

nav {
	height: 50px;
	width: 100%;
	z-index: 1;
	background-color: #4d4d4d !important;
	border-color: #4d4d4d !important;
	border-top-right-radius: 0 !important;
	border-top-left-radius: 0 !important;
}

.navbar-header {
	width: 100%;
}

.nav-icon {
	padding: 5px 15px 5px 15px;
	float: right;
}

nav a {
	color: #ccc !important;
}

nav i.fa {
	font-size: 40px;
	color: #ccc;
}

nav a:hover {
	color: #a9a9a9 !important;
}

nav i.fa:hover {
	color: #a9a9a9;
}

#main-container {
	padding-bottom: 80px;
}

.vertical-center {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.horizontal-center {
	margin: 0 auto;
}

.form-control {
	color: #0088aa;
	font-weight: bold;
}

.form-control:focus {
	border-color: #0088aa;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 170, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 136, 170, 0.6);
}

input.btn {
	font-weight: bold;
}

.btn {
	font-weight: bold !important;
}

.btn-success {
	background-color: #06d362 !important;
	border-color: #06d362;
}

.btn-success:hover {
	background-color: #1abd61 !important;
	border-color: #1abd61;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	background-color: #4d4d4d;
}

.footer .text-muted {
	margin: 20px 0;
	float: left;
	color: #ccc;
}

.openvidu-logo {
	height: 35px;
	float: right;
	margin: 12px 0;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.openvidu-logo:hover {
	-webkit-filter: grayscale(0.5);
	filter: grayscale(0.5);
}

.demo-logo {
	margin: 0;
	height: 22px;
	float: left;
	padding-right: 8px;
}

a:hover .demo-logo {
	-webkit-filter: brightness(0.7);
	filter: brightness(0.7);
}

#join-dialog h1 {
	color: #4d4d4d;
	font-weight: bold;
	text-align: center;
}

#img-div {
	text-align: center;
	position: absolute;
	top: 19%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#img-div img {
    height: 15%;
    width: 42.5%;
}

#join-dialog label {
	color: #0088aa;
}

#join-dialog input.btn {
	margin-top: 15px;
}

#session-header {
	margin-bottom: 20px;
}

#session-title {
	display: inline-block;
}

#buttonLeaveSession, #buttonSwitchCamera {
	float: right;
	margin-top: 20px;
}

#video-container video {
	position: relative;
	float: left;
	cursor: pointer;
}

#video-container p {
	/* display: inline-block;
	background: #f8f8f8;
	padding-right: 5px;
	padding-left: 5px;
	color: #777777;
	font-weight: bold;
	border-bottom-right-radius: 4px; */
}

/*video {
	width: 100%;
	height: auto;
}*/

#main-video p {
	position: absolute;
	display: inline-block;
	background: #f8f8f8;
	padding-right: 5px;
	padding-left: 5px;
	left: 0;
	font-size: 22px;
	color: #777777;
	font-weight: bold;
	border-bottom-right-radius: 4px;
}

#main-video video {
	cursor: initial;
}

#session img {
	width: 100%;
	height: auto;
	display: inline-block;
	object-fit: contain;
	vertical-align: baseline;
}

#session #video-container img {
	position: relative;
	float: left;
	width: 50%;
	cursor: pointer;
	object-fit: cover;
	height: 180px;
}


/* xs ans md screen resolutions*/

@media screen and (max-width: 991px) {
	.vertical-center {
		width: 85%;
		padding-top: 10px;
		top: 57%;
	}
}

/* // Style  */

.grid-container {
	/* display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Adjust minmax as needed 
	gap: 10px;  Space between grid items 
	padding: 30px;
	background: #000000a1;
	border-radius: 30px;
  overflow: auto;
  scrollbar-width: none; */
    /* height: 550px; */

  display: flex;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Adjust minmax as needed */
	gap: 10px; /* Space between grid items */
	padding: 35px;
	height: 226px !important;
	/* margin-top: 385px !important; */
	background: #000000a1;
	border-radius: 15px;
	overflow-y: hidden;
	overflow-x: auto;
	/* scrollbar-width: thin; */
  }
 

  
  .stream-container {
	/* border: 1px solid #ddd; 
	border-radius: 4px; 
	overflow: hidden;  */
    /* position: relative;
    height: 185px !important;
    border: 1px solid #000;
    border-radius: 21px;
    overflow: hidden;
	  background-color: #000; */
    flex: 0 0 auto;
    width: 300px;
    overflow: hidden;  
    position: relative;
    border: 1px solid #000;
    border-radius: 21px;
    overflow: hidden;
	  background-color: #000;
  }
  
  
  .mute-button {
	/* background-color: rgb(190 190 190 / 50%); */
	  background-color: rgb(10 26 45);
    color: white;
    border: none;
    border-radius: 25px;
    padding: 5px 11px;
    /* float: right; */
    margin: 5px;
    position: absolute;
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 3px;
  }
  

  div.dropdown-menu.show{
	width:215px !important;
	padding-left: 15px !important;
  }
