#join {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#join-dialog {
    width: 70%;
    max-width: 900px;
    padding: 60px;
    border-radius: 6px;
    background-color: #f0f0f0;
}

#join-dialog h2 {
    color: #4d4d4d;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

#join-dialog form {
    text-align: left;
}

#join-dialog label {
    display: block;
    margin-bottom: 10px;
    color: #0088aa;
    font-weight: bold;
    font-size: 20px;
}

.form-control {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #0088aa;
    font-weight: bold;
}

.form-control:focus {
    color: #0088aa;
    border-color: #0088aa;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 136, 170, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 136, 170, 0.6);
}

#join-dialog button {
    display: block;
    margin: 20px auto 0;
}

.btn {
    font-weight: bold;
}

.btn-success {
    background-color: #06d362;
    border-color: #06d362;
}

.btn-success:hover {
    background-color: #1abd61;
    border-color: #1abd61;
}

#room {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    margin-bottom: 20px;
}

#room-title {
    font-size: 2em;
    font-weight: bold;
    margin: 0;
}

#layout-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    height: 100%;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    #join-dialog {
        width: 90%;
        padding: 30px;
    }

    #join-dialog h2 {
        font-size: 50px;
    }

    #layout-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 480px) {
    #join-dialog {
        width: 100%;
        padding: 20px;
    }

    #join-dialog h2 {
        font-size: 40px;
    }

    #layout-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}



#join {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

#join-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#room {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    color: white;
}

#layout-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    overflow-y: auto;
    background-color: #e0e0e0;
}

.video-container, .audio-container {
    margin: 10px;
    position: relative;
}

.video-container video, .audio-container audio {
    width: 300px;
    height: 200px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: black;
}

/* .participant-name {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
} */

.btn {
    margin-left: 10px;
}
















.grid-container {
	display: grid;
	/* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); Adjust minmax as needed */
	gap: 10px; /* Space between grid items */
	padding: 35px;
	background: #000000a1;
	border-radius: 15px;
    /* height: 550px; */
  }
  
  .stream-container {
	/* border: 1px solid #ddd; 
	border-radius: 4px; 
	overflow: hidden;  */
	/* position: relative; */
	border: 1px solid #000;
    border-radius: 21px;
    overflow: hidden;
	background-color: #000;
  }


.stream-container {
	padding: 0;
}

video {
    background: transparent;
    display: block;
    transition: opacity 0.2s ease; /* Smooth appearance */
    opacity: 1; /* Fully visible */
}

video.loading {
    opacity: 0; /* Hidden while loading */
}


  /* Style for the entire toolbar */
.toolbar {
	display: flex;
	justify-content: center;
	padding: 10px;
	background-color: #00000061; /* Example background color for the toolbar */
	border-radius: 50px; /* Border radius for the toolbar */
	margin: 20px ;
	width: 590px;  /*54z5*/
  }

  .toolbar2 {
	display: flex;
	justify-content: center;
	padding: 11px;
	background-color: #00000061; /* Example background color for the toolbar */
	border-radius: 50px !important; /* Border radius for the toolbar */
	margin-right: 10px ;
	margin-top: 20px;
	/* width: 54%; */
	float: left;
  }

  .ops{
	background:unset !important;
  }

  .redbutton{
	background:red !important;
  }
  
  /* Style for individual buttons */
  .toolbar2 button {
	font-weight: 400 !important;
    margin: 0 3px;
    font-size: 18px;
    width: 50px;
	height: 50px;
    /* background: #007bff; */
    /* width: 60px; */
    border: none;
    border-radius: 50px !important;
    padding: 0px 0px;
    cursor: pointer;
    transition: background-color 0.3s;
    /* background: unset !important; */
    color: white;
    font-size: 21px !important;
  }

  .toolbar button {
	font-weight: 400 !important;
	margin: 0 10px;
	font-size: 18px;
	/* background: #007bff;  */
	color: #ffffff; 
	/* width: 60px; */
	border: none;
	border-radius: 50px; 
	padding: 13px 15px; 
	cursor: pointer;
	transition: background-color 0.3s; /* Smooth transition for hover effect */

  }
  
  /* Hover effect for buttons */
  .toolbar2 .ops:hover {
	background-color: #10101065 !important; 
  }

  .toolbar2 .redbutton:hover {
	opacity: 0.8;
  }
  
  /* Focus effect to remove default outline and add a custom one */
  .toolbar2 button:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(0,123,255,.5); /* Custom focus outline */
  }
  
  .timer {
	/* margin-right: 15px; */
	font-size: 16px;
	/* background-color: #ddd; */
	padding: 5px 10px;
	border-radius: 5px;
	width: 308px;
	text-align: right;
  }





.grid-container {
    display: flex;
    gap: 10px;
    overflow-x: auto;  /* new */
    /* justify-content: center;  Prev */
}



.stream-container {
    position: relative;
    width: 260px;
    /* height: 155px;  prev*/
    flex : 0 0 auto; /* new */
    border-radius: 10px;
    overflow: hidden;
    /* border: 2px solid #00b3ff; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.participant-name {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #1C1C1E;
    color: white;
    padding: 4px 8px;
    border-radius: 25px;
    font-size: 14px;
}

.audio-icon {
    /* position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 6px;
    border-radius: 50%;
    font-size: 18px; */

    background-color: #1C1C1E;
    position: absolute;
    bottom: 7px;
    right: 5px;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 5px 11px;
    /* float: right; */
    margin: 5px;
}
